import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Footer from '../../components/Footer'

import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet'
import { useAuthDispatch } from '../../context/auth'
import { useAuthState } from '../../context/auth'





import { makeStyles } from '@material-ui/core/styles';


const einlass = Date.parse('20 Feb 2021 09:30:00');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: '84px',
  },
}));

const TITLE = 'Willkommen - New Story'


export default function Home(props) {
  const classes = useStyles()
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const authDispatch = useAuthDispatch()
  const { user } = useAuthState()



  const logout = () => {
    authDispatch({ type: 'LOGOUT' })
}


  const handleClose = () => {
    setOpen(false);
  };
  const handleClickStream = () => {
    let current = new Date()
    if(user.uuid === "800bf81a-af90-4381-b070-2c27e5890904" || user.uuid === "6f528ab3-9290-4807-b7eb-9e90d189e83f" ||user.uuid === "38ec86bf-4135-4eb5-90fe-410d6512171d" ||user.uuid === "fdb4f7f5-2175-4b04-a507-8fee7dc6d479"){
      history.push("/home");

    }
    else if (einlass > current) {
      setOpen(true);
    }
    else {
      history.push("/home");

    }
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className={classes.root}>
        <CssBaseline />
        <main id="main-welcome" className={classes.content}>
          <div className={classes.toolbar} />
          <div className="welcome-container ">
            <div className="myBox">
              <h1><b>HERZLICH WILLKOMMEN ZU NEW STORY</b></h1>
              <br />
              <br />
              <p>Erstmal Respekt, dass du dich dazu entschlossen hast, deine Zeit zu nutzen und in dich selbst zu investieren! <b>Bereits dieser Punkt unterscheidet dich von 95% der Anderen.</b> Bevor das Event startet, haben wir drei Empfehlungen für dich:</p>
              <br />
            
              <p>1. <b>Sei mit 100% Fokus dabei.</b> Alle Themen von Anfang bis Ende ergeben zusammen ein Gesamtbild. </p>
              <br />
              <p>2. <b>Lege dir Zettel &amp; Stift bereit.</b> Wir werden erstmal keine Aufzeichnung veröffentlichen. Daher ist es unglaublich wichtig, dass du dir Notizen machst. Sonst ist alles wieder schnell vergessen. Gerade auch, wenn du einige Punkte schon kennst, macht es Sinn diese schriftlich zu verinnerlichen. </p>
              <br />
              <p>3. <b>Stell uns Fragen!</b> Wir sind uns bewusst, dass Fragen aufkommen können. Am Ende der Veranstaltungen wird es eine Q&amp;A geben, in der wir ehrlich alle offenen Punkte beantworten. </p>
              <br />
              <p><b>Jetzt aber erstmal viel Spaß!
              Ben Panther &amp; Sascha Müller</b>
</p>
              <br />
              <br />

              <Button
                className="btn"
                variant="contained"
                onClick={handleClickStream}
              >Zum Live-Stream</Button>

            </div>

          </div>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Am Samstag, den 20.02.2021 ist es soweit.</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p>Ab 09:30 Uhr, gelangst du zum Live-Stream.</p>
                <p>Um 10:00 Uhr beginnt das Event.</p>


          </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => logout()} color="primary">
                Ausloggen
          </Button>
              <Button onClick={handleClose} color="primary">
                Ok
          </Button>
            </DialogActions>

          </Dialog>
        </main>
      </div>
      <Footer/>
    </>
  )
}

