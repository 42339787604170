import React, { Fragment } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { Container } from '@material-ui/core';



import ApolloProvider from './ApolloProvider'
import { ThemeProvider } from 'styled-components'
import { AuthProvider } from './context/auth'
import { MenueProvider } from './context/menue'
import { MessageProvider } from './context/message'



import './App.scss';

import Home from './pages/home/Home'
import Welcome from './pages/home/Welcome'
import Landing from './pages/Landing'
import DynamicRoute from './util/DynamicRoute'





const theme = {
  countdownText: 'teal',
  countdownNumber: 'teal',
  primaryBtn: 'teal',
  secondaryBtn: 'orange',
  tertiaryBtn: 'grey',
  bgHeader: 'grey',
  bgFooter: 'grey',
  logoHeader: 'https://resources.wirkung.stream/projects/123/theme/logo.png',

}

function App() {


  return (
    <Fragment>
      <ApolloProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <MenueProvider>
              <MessageProvider>
                <BrowserRouter >
                  <Container style={{ padding: '0', maxWidth: "100%" }}>
                    <Switch>
                      <DynamicRoute exact path="/" component={Welcome} authenticated />
                      <DynamicRoute path="/Home" component={Home} authenticated />
                      <DynamicRoute path="/landing" component={Landing} guest />
                    </Switch>
                  </Container>
                </BrowserRouter>
              </MessageProvider>
            </MenueProvider>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Fragment>

  );
}

export default App;
