import React from 'react'
import classNames from 'classnames'






export default function HomeSection() {

    const markup =

        <div className="pt-3">
            <div className={classNames('d-flex px-3 justify-content-start')}>
                <h5 style={{ color: 'white' }}><b>BLOCK I</b></h5>
            </div >
            <div className={classNames('d-flex px-3 pt-2 justify-content-start')}>
            <p style={{ color: 'white' }}><b>Ben Panther &amp; Sascha Müller:</b></p>
            <br/>   
            </div >

            <div className={classNames('d-flex px-3 pb-2 justify-content-start')}>
            <p style={{ color: 'white' }}>Wie funktionieren digitale Events</p>
            <br/>            
            </div >
            <div className={classNames('d-flex px-3 py-2 justify-content-start')}>
            <p style={{ color: 'white' }}>Interview: <b>Andreas Klar</b></p>
            <br/>              
            </div >
            <div className={classNames('d-flex px-3 py-2 justify-content-start')}>
            <p style={{ color: 'white' }}>Interview: <b>Peter Brandl</b></p>
            <br/>  
            <br/>  
            </div >



            <div className={classNames('d-flex px-3 py-2 justify-content-start')}>
                <h5 style={{ color: 'white' }}>PAUSE</h5>
            </div >



            <div className={classNames('d-flex px-3 pt-3 justify-content-start')}>
                <h5 style={{ color: 'white' }}><b>BLOCK II</b></h5>
            </div >
            <div className={classNames('d-flex px-3 pt-2 justify-content-start')}>
            <p style={{ color: 'white' }}><b>Diskussionsrunde</b></p>
            <br/>   
            </div >
            <div className={classNames('d-flex px-3 pb-2 justify-content-start')}>
            <p style={{ color: 'white' }}>Erfahrungsaustausch: Was ist jetzt wichtig?</p>
            <br/>  
            </div >
            <div className={classNames('d-flex px-3 pt-2 justify-content-start')}>
            <p style={{ color: 'white' }}><b>Ben Panther &amp; Sascha Müller:</b></p>
            <br/>   
            </div >
            <div className={classNames('d-flex px-3 pb-2 justify-content-start')}>
            <p style={{ color: 'white' }}>Plattform &amp; Homepage</p>
            <br/>  
            </div >
            <div className={classNames('d-flex px-3 py-2 justify-content-start')}>
            <p style={{ color: 'white' }}>Interview: <b>Hermann Fürstenau</b></p>
            <br/>  
            </div >
            <div className={classNames('d-flex px-3 py-2 justify-content-start')}>
            <p style={{ color: 'white' }}>Interview: <b>Philip Semmelroth</b></p>
            <br/>  
            <br/>  
            </div >



            <div className={classNames('d-flex px-3 py-2 justify-content-start')}>
                <h5 style={{ color: 'white' }}>PAUSE</h5>
            </div >



            <div className={classNames('d-flex px-3 pt-3 justify-content-start')}>
                <h5 style={{ color: 'white' }}><b>BLOCK III</b></h5>
            </div >
            <div className={classNames('d-flex px-3 pt-2 justify-content-start')}>
            <p style={{ color: 'white' }}><b>Sascha Müller:</b></p>
            <br/>   
            </div >
            <div className={classNames('d-flex px-3 pb-2 justify-content-start')}>
            <p style={{ color: 'white' }}>Wie gewinnst du mit Social Media Kunden?</p>
            <br/>  
            </div >
            <div className={classNames('d-flex px-3 pt-2 justify-content-start')}>
            <p style={{ color: 'white' }}><b>Ben Panther:</b></p>
            <br/>   
            </div >
            <div className={classNames('d-flex px-3 pb-2 justify-content-start')}>
            <p style={{ color: 'white' }}>Studios nutzen &amp; Onlinekurse produzieren</p>
            <br/>  
            </div >
            <div className={classNames('d-flex px-3 py-2 justify-content-start')}>
            <p style={{ color: 'white' }}><b>Q&amp;A</b></p>
            <br/>  
            </div >
            <div className={classNames('d-flex px-3 py-2 justify-content-start')}>
            <p style={{ color: 'white' }}><b>Fazit, letzte Tipps &amp; Closing</b></p>
            <br/>  
            </div >
            
        </div>




    return (
        <div md={4} className="px-0">
            {markup}
        </div>

    )
}
