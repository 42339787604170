import React from 'react'
import styled from 'styled-components'



const FooterComponent = styled.footer`
`

const Flex = styled.div`
margin: 0;
 display: flex;
  flex-flow: column wrap;
  justify-content: center;
  

  @media (min-width: 768px) {
    flex-direction: row;
    
  }
`
const FlexItem = styled.div`
 flex-basis: auto;
 margin-left: 10px;
 border-left: solid 0px white;


 @media (min-width: 768px) {
  border-left: solid 1px white;

    &:first-of-type{
     border-left: solid 0px white;
    margin-bottom:0px;
}
  }

`



export default function Footer({id}) {

  return (
    <FooterComponent className="footerComponent" id={id}>
       <Flex className="flex footer">
        <FlexItem className="flex-item footer-item">
          <a href="https://wirkung.group/events/47-2/" target="_blank" rel="noreferrer">Impressum</a>
        </FlexItem>
        <FlexItem className="flex-item footer-item">
          <a href="https://wirkung.group/events/datenschutz/" target="_blank" rel="noreferrer">Datenschutz</a>
        </FlexItem>
        <FlexItem className="flex-item footer-item">
          <a href="https://wirkung.group/events/haftungsausschluss/" target="_blank" rel="noreferrer">Haftungsausschluss</a>
        </FlexItem>
        <FlexItem className="flex-item footer-item">
          <a href="https://wirkung.group/digital/wirkung-plattform/" target="_blank" rel="noreferrer">Technik</a>
        </FlexItem>
      </Flex>
    </FooterComponent>
  )
}
