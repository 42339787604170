
import React, { Fragment } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import styled from 'styled-components'

const Number = styled.span`
  font-size: 6rem;
  font-weight: 700;
`
const Text = styled.span`
  font-size: 2rem;
  font-weight: 600;
`
const Hr= styled.hr`
 height: 3px;
  margin: 0;
`

const renderer = ({ days, hours, minutes, seconds }) => (
  <Fragment>
    <div className="col-xl-1 col-lg-2 col-md-4 col-sm-8 my-4 mx-3 text-center countdown-number"><Number>{zeroPad(days)}</Number>
      <Hr /><Text className="countdown-text">Tage</Text>
    </div>
    <div className="col-xl-1 col-lg-2 col-md-4 col-sm-8 my-4 mx-3 text-center countdown-number"><Number>{zeroPad(hours)}</Number>
      <Hr /><Text className="countdown-text">Stunden</Text>
    </div>
    <div className="col-xl-1 col-lg-2 col-md-4 col-sm-8 my-4 mx-3 text-center countdown-number"><Number>{zeroPad(minutes)}</Number>
      <Hr /><Text className="countdown-text">Minuten</Text>
    </div>
    <div className="col-xl-1 col-lg-2 col-md-4 col-sm-8 my-4 mx-3 text-center countdown-number"><Number>{zeroPad(seconds)}</Number>
      <Hr /><Text className="countdown-text">Sekunden</Text>
    </div>
  </Fragment>

);







export default function MyCountdown(props) {

  return (
    <Countdown date={"2021-02-20T10:00:00"} renderer={renderer} />

  )
}
