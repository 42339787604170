import React from 'react'
import { useAuthState } from '../context/auth'
import { Route, Redirect } from 'react-router-dom'

export default function DynamicRoute(props) {
    const { user } = useAuthState()

    if (props.authenticated && !user) {
        return <Redirect push to="/landing" />
    } else if (props.guest && user) {
        return <Redirect push to="/" />    

    } else {
        return <Route component={props.component}{...props} />
    }

} 