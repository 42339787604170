  
import React, { createContext, useReducer, useContext } from 'react'

const MenueStateContext = createContext()
const MenueDispatchContext = createContext()

const menueReducer = (state, action) => {

  const { menueItem } = action.payload
  switch (action.type) {
    case 'SET_MENUE':
      return {
        ...state,
        navigator: action.payload,
      }
      case 'SET_NAV_POSITION':
      return {
        ...state,
        navPosition: action.payload,
      }
      case 'SET_AGENDA':
        return {
          ...state,
          agenda: action.payload,
        }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export const MenueProvider = ({ children }) => {
  const [state, dispatch] = useReducer(menueReducer, { navigator: null })

  return (
    <MenueDispatchContext.Provider value={dispatch}>
      <MenueStateContext.Provider value={state}>
        {children}
      </MenueStateContext.Provider>
    </MenueDispatchContext.Provider>
  )
}

export const useMenueState = () => useContext(MenueStateContext)
export const useMenueDispatch = () => useContext(MenueDispatchContext)