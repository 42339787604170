import React, { useState, useEffect, Fragment } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Helmet } from 'react-helmet'





import CssBaseline from '@material-ui/core/CssBaseline'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import md5 from 'md5'
import Footer from '../../components/Footer'


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Sidebar from '../../components/Sidebar'
import { useAuthState } from '../../context/auth'

const TITLE = 'Stream - New Story'

const ADD_QUESTION = gql`
  mutation addQuestion($from: String!, $content: String!) {
    addQuestion(from: $from ,content: $content) {
      content
    }
  }
`;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: '84px',
  },
}));


const userID = "1"
const time = "" + Date.now() / 1000 | 0
const delay = "43200"
const geheim = "mTrYUwdN8hDq"


const hash = md5(userID + time + delay + geheim).toLowerCase()
const isrc = "https://start.video-stream-hosting.de/player.html?serverip=195.201.63.108&serverapp=wirkungdigital-live&streamname=livestream.smil&userID=" + userID + "&time=" + time + "&delay=" + delay + "&hash=" + hash









export default function Home(props) {
  const classes = useStyles();
  const { user } = useAuthState()

  

  const [open, setOpen] = React.useState(false);

  const handleClickQuestion = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseProfile = () => {
    setOpen(false);
  };
  const handleSubmitProfile = () => {    
    addQuestion({ variables: { content: variables.content  } });
    console.log({variables})
    setOpen(false);
  };


  const [variables, setVariables] = useState({
    content: '',
  })
  const [addQuestion, { data }] = useMutation(ADD_QUESTION);


  const handleSubmitQuestion = () => {    
    addQuestion({ variables: { from: user.uname, content: variables.content  } });
    setOpen(false);
  };



  useEffect(() => {

    const script = document.createElement('script')
    script.src = "https://go.arena.im/public/js/arenachatlib.js?p=qyweu2a9yz&e=ovt4"
    script.async = true
    document.body.appendChild(script)
    const contentWall = document.createElement('script')
    contentWall.src = "https://go.arena.im/public/js/arena.widget.embed.lib.js"
    contentWall.async = true;
    document.body.appendChild(contentWall);

    return () => {
      document.body.removeChild(contentWall);
      document.body.removeChild(script);


    }
  }, []);


  return (
    <Fragment>
    <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar />
      <main className={classes.content}  >
        <div className={classes.toolbar} />
        <div className="home-outer">

        <div className="home-bg"> 
        <img src="https://resources.wirkung.stream/projects/123/theme/home-bg.jpg"/>

        </div>
        <div className="home-gradient"> 
        <div className="container home-content">
          <div className='videodiv' id='vdiv'><iframe  titel="New-Story" width="100%" height="100%" className="videoiframe" allowFullScreen src={isrc}></iframe></div>
          <div className="video-footer">
            <h4>Aktuell: New-Story</h4>
            <Button
              className="btn"
              variant="contained"
              onClick={handleClickQuestion}
            >Frage einreichen</Button>
          </div>
          <div className="arena-embed-widget" data-publisher="qyweu2a9yz" data-type="social-wall" data-showtitle="false" data-lang="de" data-timeline="nrzr"></div>
          </div>
         
        </div>
        <Footer id="home-footer" />


        </div>

      
      

          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Frage einreichen</DialogTitle>
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmitQuestion()
                
              }}
            >
              <DialogContent>
                <DialogContentText>
                  Sie können hier Ihre Frage direkt an den Referenten einreichen.
          </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="question"
                  label="Ihre Frage.."
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  onChange={e => setVariables({ ...variables, content: e.target.value })}

                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Abbrechen
          </Button>
                <Button color="primary" type="submit">
                  Einreichen
          </Button>
              </DialogActions>
            </form>            
          </Dialog>       
        
          <div className="arena-chat" data-publisher="qyweu2a9yz" data-chatroom="ovt4" data-position="bottom"></div>
      </main>
    </div>


    </Fragment >
  );
}

