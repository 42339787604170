import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LoginForm from '../components/LoginForm'
import Countdown from '../components/Countdown/MyCountdown';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import tawkTo from "tawkto-react"
import Footer from '../components/Footer'



import { makeStyles } from '@material-ui/core/styles';

const TITLE = 'New Story'
const tawkToPropertyId = '602bd93d9c4f165d47c3d20c'
const tawkToKey = '1eulkh6ob'


const LandingPage = styled.div`
`

const Stripe = styled.div`
margin: 0;
 display: flex;
  flex-flow: row wrap;
  justify-content: center; 

`

const Flex = styled.div`
margin: 0;
 display: flex;
  flex-flow: row wrap;
  justify-content: center; 
  align-items: center;

`


const Button = styled.button`
margin: auto;

`

const FlexItem = styled.div`
 flex-basis: auto;


`

const useStyles = makeStyles({ 
  bullet: {
    display: 'inline-block',
    margin: '0 10px',
    transform: 'scale(2)',
  },

});




export default function Landing(props) {
  const myRef = useRef()
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  function executeScroll() {
    myRef.current.scrollIntoView({ behavior: 'smooth' })

  }





  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey)

    const script = document.createElement('script')
    script.src = "https://player.vimeo.com/api/player.js"
    script.async = true
    document.body.appendChild(script)  

  
    return () => {
      document.body.removeChild(script);


    }
  }, []);




  return (
    <>
    <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
    <LandingPage className="landingPage mb-5">
      <AppBar position="fixed" className="app-bar">
        <Toolbar className="app-bar-inner">
        <img 
        src="https://resources.wirkung.stream/projects/123/theme/appbar-image.png" alt=''
        className="app-bar-image" /> 
        </Toolbar>
      </AppBar>


      <Stripe className="stripe">
      

      <Flex>
<Container>
<Row>

<Col md={6} sm={12} xl={6} className="myCol">
<h2>An alle Veranstalter, Speaker, Coaches und Event-Dienstleister:<br/> <b>Die Lage ändert sich!</b></h2>
<br/>
<br/>
<h3 className="d-none d-md-block" ><b>Du musst 2021 wissen:</b></h3>
<p  className="d-none d-md-block" >{bull}Wie digitale Events wirklich funktionieren<br/>
{bull}Wie du als Speaker oder Coach dein Know-How online verkaufst und Umsatz machst<br/>
{bull}Warum du deine Social Media Strategie komplett umdenken musst, um Kunden zu gewinnen</p>

</Col>


<Col md={6} sm={12} xl={6}  className="myCol ">
  <div className="wildcard">
  <div style={{padding: '56.25% 0 0 0', position: 'relative'}}><iframe src="https://player.vimeo.com/video/502623927?color=fc3c3a&title=0&byline=0&portrait=0" style={{position:'absolute', top:0, left:0, width:'100%',height:'100%', borderRadius: '10px'}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>

<Button className="btn btn-block mt-3"  onClick={executeScroll} />

  </div>


</Col>

<Col sm={12} className="myCol pt-5 d-md-none">
<h3  ><b>Du musst 2021 wissen:</b></h3>
<p   >{bull}Wie digitale Events wirklich funktionieren<br/>
{bull}Wie du als Speaker oder Coach dein Know-How online verkaufst und Umsatz machst<br/>
{bull}Warum du deine Social Media Strategie komplett umdenken musst, um Kunden zu gewinnen</p>

</Col>

</Row>
</Container>
</Flex>


      </Stripe>
      <Flex className="justify-content-center my-4">
        <Countdown />

      </Flex>

      <div ref={myRef}><LoginForm /></div>
    </LandingPage>
    <Footer />
    </>
    
  )
}
