import React, { Fragment, useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import { useMessageDispatch, useMessageState } from '../../context/message'


import Message from './Message'

const SEND_MESSAGE = gql`
  mutation sendMessage($to: String!, $content: String!) {
    sendMessage(to: $to, content: $content) {
      uuid
      from
      to
      content
      createdAt
    }
  }
`

const GET_MESSAGES = gql`
  query getMessages($from: String!) {
    getMessages(from: $from) {
      uuid
      from
      to
      content
      createdAt
    }
  }
`



const useStyles = makeStyles((theme) => ({
  multilineColor:{
    color:'black'
},
}));

export default function Messages() {
  const { users } = useMessageState()
  const dispatch = useMessageDispatch()
  const [content, setContent] = useState('')
  const classes = useStyles();


  const selectedUser = users?.find((u) => u.selected === true)
  const messages = selectedUser?.messages

  const [
    getMessages,
    { loading: messagesLoading, data: messagesData },
  ] = useLazyQuery(GET_MESSAGES)

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    onError: (err) => console.log(err),
  })

  useEffect(() => {
    if (selectedUser && !selectedUser.messages) {
      getMessages({ variables: { from: selectedUser.uuid } })
    }
  }, [selectedUser])

  useEffect(() => {
    if (messagesData) {
      dispatch({
        type: 'SET_USER_MESSAGES',
        payload: {
          uuid: selectedUser.uuid,
          messages: messagesData.getMessages,
        },
      })
    }
  }, [messagesData])

  const submitMessage = (e) => {
    e.preventDefault()
    if (content.trim() === '' || !selectedUser) return

    setContent('')

    // mutation for sending the message
    sendMessage({ variables: { to: selectedUser.uuid, content } })
  }

  let selectedChatMarkup
  if (!messages && !messagesLoading) {
    selectedChatMarkup = <p className="info-text">Nothing here</p>
  } else if (messagesLoading) {
    selectedChatMarkup = <p className="info-text">Loading..</p>
  } else if (messages.length > 0) {
    selectedChatMarkup = messages.map((message, index) => (
      <Fragment key={message.uuid}>
        <Message message={message} />
        {index === messages.length - 1 && (
          <div className="invisible">
            <hr className="m-0" />
          </div>
        )}
      </Fragment>
    ))
  } else if (messages.length === 0) {
    selectedChatMarkup = (
      <p className="info-text">
        Schreiben Sie Ihre erste Nachricht
      </p>
    )
  }
  

  
  
  return (
    <div className="messages-outer">
      <div className="messages-box">
        {selectedChatMarkup}
        </div>
        
      <div >
        <form onSubmit={submitMessage} className="message-form">        
          <TextField
          className="message-input"
          id="outlined-textarea"
          label="Schreiben Sie etwas"
          placeholder=""
          multiline
          variant="filled"
          InputProps={{
            className: classes.multilineColor
          }}

          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
         <SendIcon fontSize="large"
              className="message-send"
              onClick={submitMessage}
              role="button"
              style={{ fill: "white" }}
            ></SendIcon>
        </form>
      </div>
    </div>
  )
}