import React, { useState, useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import StarsIcon from '@material-ui/icons/Stars';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { gql } from '@apollo/client'


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import UserSection from '../pages/home/UserSection'
import HomeSection from './HomeSection'
import SponsorSection from './SponsorSection'
import MessageSection from '../pages/home/MessageSection'

import { useAuthState } from '../context/auth'

import { useAuthDispatch } from '../context/auth'
import { useMenueDispatch, useMenueState } from '../context/menue'
import { useMessageState, useMessageDispatch } from '../context/message'

const NEW_MESSAGE = gql`
  subscription newMessage {
    newMessage {
      uuid
      from
      to
      content
      createdAt
    }
  }
`



const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: '#00000080',
    },
    menuButton: {
        marginRight: theme.spacing(2),

    },
    divider: {
        // Theme Color, or use css color in quote
        background: "white",
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: 'transparent linear-gradient(180deg, #000000E0 0%, var(--unnamed-color-00000060) 100%) 0% 0% no-repeat padding-box',
        background: 'transparent linear-gradient(180deg, #000000E0 0%, #00000060 100%) 0% 0% no-repeat padding-box',
        opacity: 1,
        backdropFilter: 'blur(19px)',
        webkitBackdropFilter: 'blur(19px)',


    },
    sidebarGoBack: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    sidebarClose: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    formControl: {
        margin: theme.spacing(3),
    },

}));

export default function Sidebar(props) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const dispatch = useMenueDispatch()
    const { navigator } = useMenueState()
    const { selected } = useMessageState()
    const messageDispatch = useMessageDispatch()
    const authDispatch = useAuthDispatch()

    const { user } = useAuthState()
    /*

    const { data: messageData, error: messageError } = useSubscription(
        NEW_MESSAGE
    )
    useEffect(() => {
        if (messageError) console.log(messageError)

        if (messageData) {
            const message = messageData.newMessage
            const otherUser = user.uuid === message.to ? message.from : message.to

            messageDispatch({
                type: 'ADD_MESSAGE',
                payload: {
                    uuid: otherUser,
                    message,
                },
            })
        }
    }, [messageError, messageData])

*/



    const [openProfile, setOpenProfile] = React.useState(false);
    const [variables, setVariables] = useState({
        uname: '',
        takepart: false,
    })


    const handleCloseProfile = () => {
        setOpenProfile(false);
    };
    const handleOpenProfile = () => {
        setOpenProfile(true);
    };
    const handleSubmitProfile = () => {
        // addQuestion({ variables: { content: variables.content  } });
        console.log({ variables })
        setOpenProfile(false);
    };

    const menueIcons = [<InfoIcon style={{ fill: "white" }} />, <StarsIcon style={{ fill: "white" }} />]
    const menueItemName = ['Agenda', 'Sponsoren',]


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleGoBack = () => {
        dispatch({ type: 'SET_MENUE', payload: "Start" })
        messageDispatch({ type: 'UNSELECT', payload: null })

    };

    const logout = () => {
        authDispatch({ type: 'LOGOUT' })
    }



    /* <ListItem button key="Profil" onClick={() => handleOpenProfile()}>
                    <ListItemIcon >
                        <AccountCircleIcon style={{ fill: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ color: "white" }}>Profil</Typography>} />
                </ListItem>*/
    let drawer =
        <div>
            <div className={classes.toolbar} />
            <List>
                {menueItemName.map((name, index) => (
                    <ListItem button key={name} onClick={() => dispatch({ type: 'SET_MENUE', payload: name })}>
                        <ListItemIcon  >
                            {menueIcons[index]}
                        </ListItemIcon>
                        <ListItemText primary={<Typography style={{ color: "white" }}>{name}</Typography>} />
                    </ListItem>
                ))}

                <ListItem button key="Logout" onClick={() => logout()}>
                    <ListItemIcon >
                        <PowerSettingsNewIcon style={{ fill: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary={<Typography style={{ color: "white" }}>Ausloggen</Typography>} />
                </ListItem>
            </List>
        </div>


    if (!navigator || navigator === "Start") {
        drawer =
            <div>
                <div className={classes.toolbar, classes.sidebarClose}><CloseIcon style={{ fill: "white", zIndex: 2500 }} className="goBackIcon" onClick={handleDrawerToggle} /></div>

                <List>
                    {menueItemName.map((name, index) => (
                        <ListItem button key={name} onClick={() => dispatch({ type: 'SET_MENUE', payload: name })}>
                            <ListItemIcon >
                                {menueIcons[index]}
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{ color: "white" }}>{name}</Typography>} />
                        </ListItem>
                    ))}

                    <ListItem button key="Logout" onClick={() => logout()}>
                        <ListItemIcon >
                            <PowerSettingsNewIcon style={{ fill: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography style={{ color: "white" }}>Ausloggen</Typography>} />
                    </ListItem>
                </List>
            </div>
    }
    else if (selected) {
        drawer =
            <div>
                <div className={classes.toolbar} />
                <div className={classes.sidebarGoBack}>
                    <ArrowBackIosIcon
                        onClick={handleGoBack}
                        className="goBackIcon"
                        style={{ fill: "white", zIndex: 2500 }}
                    />
                </div>
                <MessageSection />

            </div>
    }
    else {
        switch (navigator) {
            case 'Privatnachrichten':
                drawer =
                    <div>
                        <div className={classes.toolbar} />
                        <div className={classes.sidebarGoBack}>
                            <ArrowBackIosIcon
                                onClick={handleGoBack}
                                className="goBackIcon"
                                style={{ fill: "white", zIndex: 2500 }}
                            />
                        </div>
                        <UserSection />

                    </div>
                break
            case 'Agenda':
                drawer =
                    <div>
                        <div className={classes.sidebarGoBack}>
                            <ArrowBackIosIcon
                                onClick={handleGoBack}
                                className="goBackIcon"
                                style={{ fill: "white", zIndex: 2500 }}
                            />
                        </div>
                        <HomeSection />

                    </div>
                break


            case 'Sponsoren':
                drawer =
                    <div>
                        <div className={classes.sidebarGoBack}>
                            <ArrowBackIosIcon
                                onClick={handleGoBack}
                                className="goBackIcon"
                                style={{ fill: "white", zIndex: 2500 }}
                            />s
                        </div>
                        <SponsorSection />

                    </div>
                break

            case 'Support':
                drawer =

                    <div>
                        <div className={classes.toolbar} />
                        <div className={classes.sidebarGoBack}>
                            <ArrowBackIosIcon
                                onClick={handleGoBack}
                                className="goBackIcon"
                                style={{ fill: "white", zIndex: 2500 }}
                            />
                        </div>
                        <div class="spinner-border text-light spinner mt-5 ml-1" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <br />
                        <p style={{ color: 'white' }}>Cross-Site-Tracking ist deaktiviert. Bitte aktivieren es in den Einstellungen deines Browsers.</p>
                        <div className="support-wrapper">
                            <iframe src="https://tawk.to/chat/602bd93d9c4f165d47c3d20c/1eulkh6ob" name="iframe" id="iframe" scrolling="no" frameborder="0" marginheight="0px" marginwidth="0px" height="100%" width="100%"></iframe>

                        </div>

                    </div>


                break



            default:
            //throw new Error(`Unknown Menue type: ${navigator}`)
        }
    }


    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <div className={classes.appBar}>
                <AppBar position="fixed" className="app-bar home-app-bar">
                    <Toolbar className="app-bar-inner">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                         <img 
        src="https://resources.wirkung.stream/projects/123/theme/appbar-image.png" alt=''
        className="app-bar-image x-center" /> 
        <p></p>
                    </Toolbar>
                </AppBar>
            </div>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>


            <Dialog open={openProfile} onClose={handleCloseProfile} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Profil bearbeiten</DialogTitle>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmitProfile()

                    }}
                >
                    <DialogContent>
                        <DialogContentText>
                            Sie können hier Ihren Nutzernamen anpassen und festlegen, ob die anderen Teilnehmer Sie anschreiben können.
          </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="question"
                            label="Ihr Nutzername"
                            type="text"
                            fullWidth
                            rows={4}
                            variant="outlined"
                            onChange={e => setVariables({ ...variables, uname: e.target.value })}

                        />

                        <FormControlLabel
                            control={<Checkbox checked={false} onChange={e => setVariables({ ...variables, takepart: e.target.value })} name="takepart" />}
                            label="An Privatnachrichten teilnehmen"
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseProfile} color="primary">
                            Abbrechen
          </Button>
                        <Button color="primary" type="submit">
                            Speichern
          </Button>
                    </DialogActions>
                </form>
            </Dialog>


        </div>
    );
}

