import React, { useState, Fragment } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import styled from 'styled-components'
import { useAuthDispatch } from '../context/auth'



const Flex = styled.div`
margin: 0;
 display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.countdownNumber}; 
`
const FlexItem = styled.div`
margin: auto;
`
const Card = styled.div`
border-radius: 3px;
border: none;
    min-width: 300px;
    max-width: 600px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #E7E8EE;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);    
`
const CardHeader = styled.div`
border-top-left-radius: 5px;
border-top-right-radius: 5px;
`
const Form = styled.form`
`
const Input = styled.input`
    padding: 15px;
    width: 100%;
    border: none;
`
const Button = styled.button`
display: block; 
width: 100%;
border: none;
`
const LOGIN_USER = gql`
  query login($email: String!, $password: String!){
    login(email: $email, password: $password){
      username, uuid, token
    }
  }
`;

export default function Login(props) {

  const [variables, setVariables] = useState({
    email: '',
    password: '',
  })

  const [errors, setErorrs] = useState({})


  const dispatch = useAuthDispatch()

  const [loginUser, { loading }] = useLazyQuery(LOGIN_USER, {
    onError(err) {
      setErorrs(err.graphQLErrors[0].extensions.errors)
    },
    onCompleted(data) {
      dispatch({ type: 'LOGIN', payload: data.login })
      window.location.href = '/'
    }
  });

  const submitLoginForm = e => {
    e.preventDefault()
    loginUser({ variables })
    

  }


  return (
    <Fragment>
      <Flex className="loginForm bg-white py-5 justify-content-center">
        <FlexItem sm={10} md={8} lg={6} xl={3} className="my-auto">
          <Form onSubmit={submitLoginForm} style={{textAlign: "center"}}>
            <Card className="login-card-header">              
              <CardHeader className="login-card-header "><h3>Login</h3></CardHeader>
              <div>
              <Input
                type="email"
                value={variables.email}
                onChange={e => setVariables({ ...variables, email: e.target.value })}
                placeholder={errors.email ?? 'E-Mail-Adresse'}
              />
              <Input
                type="password"
                value={variables.password}
                onChange={e => setVariables({ ...variables, password: e.target.value })}
                placeholder={errors.password ?? 'Passwort'}
              />
              </div>            
              <Button className="login-btn" type="submit" disabled={loading}>{loading ? 'loading..' : 'Einloggen'}</Button>
            </Card>
            <br/>
            <span className="pt-3" style={{color: '#8f0000'}}>&nbsp;{(errors.email || errors.password) && 'Ungültige Zugangsdaten'}&nbsp;</span>
          </Form>
        </FlexItem>
      </Flex>
    </Fragment>
  )
}
