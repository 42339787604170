import React from 'react'
import classNames from 'classnames'






export default function SponsorSection() {

    const markup =

        <div>
            <div className={classNames('d-flex p-3 justify-content-center')}>
            <a href="https://hallosascha.de/" target="_blank" rel="noreferrer"> <img src={'https://resources.wirkung.stream/projects/123/theme/sponsor1.jpg'} alt='' className="sponsor-image" /></a>
            </div >    
            <div className={classNames('d-flex p-3 justify-content-center')}>
            <a href="https://wirkung.group/events/" target="_blank" rel="noreferrer"> <img src={'https://resources.wirkung.stream/projects/123/theme/sponsor2.jpg'} alt='' className="sponsor-image" /></a>
            </div > 
            <div className={classNames('d-flex p-3 justify-content-center')}>
            <a href="https://wirkung.group/digital/" target="_blank" rel="noreferrer"> <img src={'https://resources.wirkung.stream/projects/123/theme/sponsor3.jpg'} alt='' className="sponsor-image" /></a>
            </div >    
            <div className={classNames('d-flex p-3 justify-content-center')}>
            <a href="https://event-university.de/" target="_blank" rel="noreferrer"> <img src={'https://resources.wirkung.stream/projects/123/theme/sponsor4.jpg'} alt='' className="sponsor-image" /></a>
            </div > 
                         
        </div>




    return (
        <div md={4} className="px-0">
            {markup}
        </div>

    )
}
